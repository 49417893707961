<template>
  <div>
    <v-overlay :value="showOverlay"></v-overlay>
    <show-model :text="model"></show-model>
    <v-card v-if="!isSuperUserUniversity">
      <v-card-title>ข้อมูลส่วนตัว</v-card-title>
      <v-card-text class="pt-7">
        <CustomSecureBlock
          ref="maintainForm"
          :rows="rows"
          v-model="model"
          id="111"
          @click-submit="submitChangeProfile"
        ></CustomSecureBlock>
      </v-card-text>
    </v-card>

    <v-card
      v-if="isMember"
      class="mt-5"
    >
      <v-card-title>ประวัติทีม</v-card-title>
      <v-card-text class="pb-4">
        <TeamHistory></TeamHistory>
      </v-card-text>
    </v-card>

    <!-- <line-connect></line-connect> -->

    <v-expansion-panels
      inset
      class="mt-5"
    >
      <!-- change password -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="title-header">
            <v-icon class="mr-2">mdi-lock </v-icon> Change Password
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <change-password></change-password>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- change password -->
    </v-expansion-panels>
    <!-- <change-password ></change-password> -->
  </div>
</template>

<script>
import ChangePassword from "./change-password";
import LineConnect from "./line-connect";
import TeamHistory from "./../team-history.vue";
import CustomSecureBlock from "./../../components/custom/custom-secure-block";

import { createDynamicModel } from "./../../js/custom";
import { InputType, UserRole } from "./../../js/constants";
// import { isSuperUserUniversity } from "./../../js/authorization";

import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";
import { profile } from "./../../_specific/exportJs";

export default {
  components: {
    "change-password": ChangePassword,
    "line-connect": LineConnect,
    CustomSecureBlock,
    TeamHistory,
  },
  data() {
    return {
      // isSuperUserUniversity,
      model: {},
      showOverlay: false,
    };
  },
  created() {
    this.initModel();

    if (!this.isSuperUserUniversity) {
      this.getProfile();
    }
  },
  methods: {
    getProfile() {
      let url = "/account/profile";
      // url = getFakeUrl(fakeAbbr.profile.get) + url;

      this.showOverlay = true;
      this.$http
        .get(url)
        .then((res) => {
          this.model = res;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    submitChangeProfile() {
      let url = profile.getUrlSubmit(this);

      this.showOverlay = true;
      this.$http
        .post(url, this.model)
        .then((res) => {
          this.model = res;
          this.showAlertSuccess();
          this.$refs.maintainForm.cancelEditMode();
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    initModel() {
      this.model = {};
      createDynamicModel(this, this.model, this.rows);
    },
  },
  computed: {
    rows() {
      return profile.getProfileView(this);
    },
  },
};
</script>
