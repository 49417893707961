<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th>No.</th>
          <th>ชื่อทีม</th>
          <th>จำนวนเลือด (ซีซี)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in teams"
          :key="index"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.toTalBloodCC| currency(0) }}</td>
        </tr>

        <tr
          v-show="teams.length === 0"
          class="border-no-result"
        >
          <td
            colspan="100%"
            class="text-center"
          >No Result</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teams: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = "/account/profile/teamhistories";
      this.$http.get(url).then((res) => {
        this.teams = res.items;
      });
    },
  },
};
</script>
